
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import TextControl from "@/components/controls/base/TextControl.vue";
import EnumControl from "@/components/controls/base/EnumControl.vue";
import DecimalControl from "@/components/controls/base/DecimalControl.vue";
import CheckBoxControl from "@/components/controls/base/CheckBoxControl.vue";

export default defineComponent({
  components: {
    CreateEditDialog,
    TextControl,
    EnumControl,
    DecimalControl,
    CheckBoxControl,
  },
  props: {
    saveModelCallback: { required: true },
  },
  mixins: [mountComponentMixin],
  methods: {
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
    onShown(model: any) {
      (this as any).mountComponent(
        "components/catalogues/RequisiteCatalogue/RequisiteEquipmentMaterialSubCatalogueGrid.vue",
        (this as any).equipmentMaterialSubGridDomId,
        {
          parentModel: model,
        }
      );
    },
    beforeUnmount() {
      (this as any).unmountComponent(
        (this as any).equipmentMaterialSubGridDomId
      );
    },
  },
  data() {
    return {
      equipmentMaterialSubGridDomId:
        "RequisiteCreateEditDialog_equipmentMaterialSubGrid",
    };
  },
});
